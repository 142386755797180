import qs from 'qs'
import semaphpre from './semaphore'

export default {
  install: function (Vue, options) {
    Vue.mixin({
      data () {
        return {
          fetchConfig: { // fetch options for api requests
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'omit', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
            redirect: 'manual', // manual, *follow, error
            // referrerPolicy: 'no-referrer', // no-referrer, *client
          },
          sending: false,
          apiUrl: options.url,
          sema: new semaphpre(1)
        }
      },

      computed: {
        loggedInUser () {
          const user = localStorage.getItem('loggedInUser')
          if (!user) return null
          return JSON.parse(user)
        },
      },

      methods: {
        async apiLogin () {
          this.sending = true
          let res = await fetch(this.apiUrl + '/api/login', {
            ...this.fetchConfig,
            method: 'POST',
            body: JSON.stringify(this.form),
          })

          if (res.ok) {
            let data = await res.json()
            this._saveAuthorizationToken(data)
            this.fetchConfig.headers.Authorization = 'Bearer ' + data.access_token
            res = await fetch(this.apiUrl + '/api/profile', this.fetchConfig)
            if (res.ok) {
              data = await res.json()
              localStorage.setItem('loggedInUser', JSON.stringify(data))
              this.sending = false
              return data
            }
          }
          this.sending = false
          const error = await res.json()
          throw error
        },

        _saveAuthorizationToken (data) {
          localStorage.setItem('accessToken', data.access_token)
          let date = new Date()
          date = new Date(date.getTime() + parseInt(data.expires_in) * 1000)
          localStorage.setItem('tokenExpires', date)
        },

        async _addAuthorizationToken(headers) {
          try {
            await this.sema.acquire();
            let token = localStorage.getItem('accessToken')
            const expires = localStorage.getItem('tokenExpires')
            if (token && expires) {
              const expire_time = new Date(expires)
              const time_left = (expire_time.getTime() - new Date().getTime()) / 1000;
              if (time_left < 0) {
                this._handleUnauthenticated()
                return
              }
              if (time_left < 1200) {
                headers.headers.Authorization = 'Bearer ' + token
                const res = await fetch(this.apiUrl + '/api/refresh', {
                  ...headers,
                  method: 'POST',
                  body: null,
                })

                if (res.ok) {
                  const data = await res.json()
                  if (data.access_token) {
                    this._saveAuthorizationToken(data)
                    headers.headers.Authorization = 'Bearer ' + data.access_token
                  }
                  return(headers)
                } else {
                  this._handleUnauthenticated()
                  return
                }
              } else {
                headers.headers.Authorization = 'Bearer ' + token
                return(headers)
              }
            } else {
              this._handleUnauthenticated()
            }
          } catch (e) {
            this._handleUnauthenticated()
          } finally {
            this.sema.release();
          }
        },

        fetch (url, headers) {
          this.sending = true
          return new Promise((resolve, reject) => {
            this._addAuthorizationToken(headers).then(fullHeaders => {
              fetch(url, fullHeaders)
                .then((res) => {
                  if (res.ok) {
                      res.json().then(data => {                      
                        this.sending = false
                        resolve(data)
                      }).catch(() => {
                      this.sending = false
                      resolve(null)
                    })
                  } else {
                    if (res.status === 401) {
                      this._handleUnauthenticated()
                    }
                    res.json().then(error => {
                      this.sending = false
                      reject(error)
                    }).catch(ex => {
                      this.sending = false
                      reject(ex)
                    })
                  }
                }).catch(error => {
                  this.sending = false
                  reject(error)
                })
            })
          })
        },

        _handleUnauthenticated () {
          this.sending = false
          localStorage.removeItem('loggedInUser')
          this.$store.commit('LOGOUT')
          this.$router.push('/login')
        },

        get (url, params, method = 'GET') {
          return this.fetch(this.apiUrl + url + '?' + qs.stringify(params), {
            ...this.fetchConfig,
            method: method,
          })
        },

        show (url, id, method = 'GET') {
          return this.fetch(this.apiUrl + url + id, {
            ...this.fetchConfig,
            method: method,
          })
        },

        post (url, params, method = 'POST') {
          return this.fetch(this.apiUrl + url, {
            ...this.fetchConfig,
            method: method,
            body: JSON.stringify(params),
          })
        },

        put (url, params) {
          return this.post(url, params, 'PUT')
        },

        patch (url, params) {
          return this.post(url, params, 'PATCH')
        },

        delete (url, id = '') {
          return this.fetch(this.apiUrl + url + id, {
            ...this.fetchConfig,
            method: 'DELETE',
          })
        },

      },
    })
  },
}
