import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/auth/Login'),
    },
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      meta: {
        requiresAuth: true,
      },
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Pages
        {
          name: 'User Profile',
          path: 'pages/user',
          component: () => import('@/views/dashboard/pages/UserProfile'),
        },
        {
          name: 'Administrators',
          path: 'pages/admins',
          meta: {
            permission: 'edit admins',
          },
          component: () => import('@/views/pages/admins/UsersTable'),
        },
        {
          name: 'Notifications',
          path: 'pages/notifications',
          component: () => import('@/views/pages/users/Notifications'),
        },
        {
          name: 'Roles And Permissions',
          path: '/pages/roles',
          meta: {
            permission: 'edit roles',
          },
          component: () => import('@/views/pages/admins/roles-permissions/RolesAndPermissions')
        },
        {
          name: 'Presale prices',
          path: '/pages/presale/prices',
          meta: {
            permission: 'edit presale prices',
          },
          component: () => import('@/views/pages/admins/presale-prices/PresalePrices')
        },
        {
            name: 'Presales',
            path: '/pages/presales',
            meta: {
              permission: 'edit presales',
            },
            component: () => import('@/views/pages/admins/presales/Presales')
          },
        {
          name: 'Debug routes',
          path: '/debug/routes',
          component: () => import('@/views/debug/Routes'),
        },
        {
          name: 'Debug route',
          path: '/debug/routes/:method/:uri',
          component: () => import('@/views/debug/Route'),
        },
      ],
    },
  ],

})

function hasPermission(permissions, permission) {
  if (typeof permission === 'string') {
    return permissions.includes(permission)
  } else {
    for (const i in permission) {
      if (permissions.includes(permission[i])) {
        return true
      }
    }
    return false
  }
}

router.beforeEach((to, from, next) => {
  let loggedInUser = localStorage.getItem('loggedInUser')
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)

  if (requiresAuth) {
    if (!loggedInUser) {
      const loginpath = window.location.pathname
      next({ name: 'Login', query: { from: loginpath } })
    } else if (loggedInUser) {
      loggedInUser = JSON.parse(loggedInUser)
      if (to.meta.permission && !hasPermission(loggedInUser.permissions, to.meta.permission)) {
        next(from)
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

export default router
